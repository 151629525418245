<template>
  <div class="fill-height">
    <template v-if="isReady">
      <error-dialog />
      <success-snackbar />
      <app-bar v-model="expandOnHover" />

      <stemble-drawer :expand-on-hover.sync="expandOnHover" />

      <dashboard-view v-if="courses" :courses="courses" />
    </template>
  </div>
</template>

<script>
import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {Course} from '@/courses/models';
import AppBar from '@/appbar/AppBar';
import StembleDrawer from '@/drawer/StembleDrawer';
import DashboardView from '@/dashboard/components/DashboardView';
import {Paginator} from '@/common/utils/pagination';
import {getCoursesForUser} from '@/courses/api/courses';

export default {
  name: 'DashboardIndex',

  components: {
    AppBar,
    StembleDrawer,
    DashboardView,
  },
  data: () => ({
    expandOnHover: false,
  }),
  computed: {
    isReady() {
      return this.$auth.hasUserEverLoggedIn && this.isCoursesLoaded;
    },
    isCoursesLoaded() {
      return this.$loadingFlags.isLoaded(LoadingFlag.CurrentUserCourses);
    },
    courses() {
      return this.isCoursesLoaded ? Course.queryCoursesForUser(this.$auth.user.id, true).all() : [];
    },
  },
  created() {
    // We paginate the courses we load, as some of our admins have a lot of courses and will exceed load balancer response size limits.
    const paginator = new Paginator(
      async (pagination) => {
        const courses = await getCoursesForUser(this.$auth.user.id, {
          checkSections: true,
          includeSections: true,
          includeSubCourse: true,
          ...pagination,
        });
        await Course.insert({data: courses.data.data});

        return courses;
      },
      {pageSize: 50}
    );

    this.$loadingFlags
      .loadingHandler(LoadingFlag.CurrentUserCourses, paginator.stream())
      .catch(this.$errorReporting.errorDialogHandler);
  },
};
</script>
